
import { Options, Vue } from 'vue-class-component';
import Confetti from '@/components/Confetti.vue';
import BaseCard from '@/components/BaseCard.vue';
import ReferralSection from '@/components/ReferralSection.vue';
import analytics from '@/helpers/analytics';

@Options({
  title: 'Welcome',
  components: {
    Confetti,
    BaseCard,
    ReferralSection,
  },
  data() {
    return {
      userBalance: "1",
      communityBalance: "1",
      userRefLink: null,
    }
  },
  methods: {
    getProfile(token: string) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${token}`);
      fetch("https://ourearth.io/api/user/profile", {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      })
      .then(response => response.json())
      .then(result => {
        this.userBalance = Math.floor(result.balance.trees).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.communityBalance = Math.floor(result.total_balance.trees).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.userRefLink = `https://app.ourforest.io/invite/?referrer=${result.referral_code}`;
        document.querySelector('.welcome-view')?.classList.add('visible');
      })
      .catch(error => {
        console.log(error);
        this.$router.push({ name: 'Login' });
      })
    },
    openLink(url: string) {
      window.open(url);
      analytics.clickedLink(url);
    }
  },
  mounted() {
    document.dispatchEvent(new CustomEvent("requestOurforestUserEmail", {}));
    document.addEventListener('ourforestUserEmail', (event: any) => {
      this.getProfile(event.detail.token);
    });
  }
})
export default class Welcome extends Vue {

}
