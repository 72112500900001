<template>
  <div class="welcome-view">
    <Confetti class="welcome-view__confetti" />
    <section class="welcome-view__section">
      <img src="../assets/decidious-tree.png" alt="Decidious Tree">
      <h1 v-if="userBalance === '1'" class="welcome-view__jumbo-title">You just planted your 1st tree!</h1>
      <h1 v-else class="welcome-view__jumbo-title">Welcome to OurForest!</h1>
      
      <div class="welcome-view__both-figures-container">
        <div class="welcome-view__figure-container">
          <div class="welcome-view__figure-wrapper">
            <h2 class="welcome-view__figure-number">{{ userBalance }}</h2>
            <div class="welcome-view__figure-icon">
              <img src="../assets/evergreen-tree.png" alt="Evergreen Tree">
            </div>
          </div>
          <h4 class="welcome-view__figure-title">Your trees</h4>
        </div>
        <div class="welcome-view__figure-container">
          <div class="welcome-view__figure-wrapper">
            <h2 class="welcome-view__figure-number">{{ communityBalance }}</h2>
            <div class="welcome-view__figure-icon">
              <img src="../assets/globe-africa.png" alt="Globe Africa">
            </div>
          </div>
          <h4 class="welcome-view__figure-title">Our community's trees</h4>
        </div>
      </div>
    </section>

    <section class="welcome-view__section">
      <hr class="welcome-view__section--divider">
      <p>Welcome to our community of tree planters! We’re so happy that you’ve joined us on our mission to make the world a better place 🙌</p>
      <hr class="welcome-view__section--divider">
    </section>

    <section class="welcome-view__section">
      <h2 class="welcome-view__title">There are 5 ways to plant trees with OurForest:</h2>
      <BaseCard class="welcome-view__card">
        <h3 class="title">1. Browse the internet like normal <img class="welcome-view__emoji" src="../assets/woman-laptop.png"></h3>
        <p class="description">When you open a new tab, we’ll show an ad from a hand-picked sustainable business, and use the revenue to plant trees.</p>
        <img class="welcome-view__card--ad-screenshot" src="../assets/ad-screenshot-example.jpg" alt="example of an ad">
      </BaseCard>
      <BaseCard class="welcome-view__card">
        <h3 class="title">2. Support sustainable brands <img class="welcome-view__emoji" src="../assets/fern.png"></h3>
        <p class="description">We’ve partnered with carefully selected sustainable brands to plant trees whenever you shop at their stores.</p>
        <a @click="openLink('https://www.ourforest.io/sustainable-shopping')" class="base-link external">See their stores</a>
      </BaseCard>
      <BaseCard class="welcome-view__card">
        <h3 class="title">3. Invite friends and plant 10 trees <img class="welcome-view__emoji" src="../assets/friends.png"></h3>
        <p class="description">We’ll plant 10 trees for each person that joins OurForest. That's five for you and five for them!</p>
        <ReferralSection :userRefLink="userRefLink"></ReferralSection>
      </BaseCard>
      <BaseCard class="welcome-view__card">
        <h3 class="title">4. Leave a review and plant a tree <img class="welcome-view__emoji" src="../assets/star.png"></h3>
        <p class="description">Share your thoughts on OurForest and help save the planet while you do it.</p>
        <a @click="openLink('https://app.ourforest.io/review')" class="base-link external">Leave a review</a>
      </BaseCard>
      <BaseCard class="welcome-view__card">
        <h3 class="title">5. Hop on a call with us and plant 5 trees <img class="welcome-view__emoji" src="../assets/speech-bubble.png"></h3>
        <p class="description">We're looking to learn from you about your experience with the app so far.</p>
        <a @click="openLink('https://forms.gle/Et5SRF3C2jkcFbgY7')" class="base-link external">Schedule a time</a>
      </BaseCard>
    </section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Confetti from '@/components/Confetti.vue';
import BaseCard from '@/components/BaseCard.vue';
import ReferralSection from '@/components/ReferralSection.vue';
import analytics from '@/helpers/analytics';

@Options({
  title: 'Welcome',
  components: {
    Confetti,
    BaseCard,
    ReferralSection,
  },
  data() {
    return {
      userBalance: "1",
      communityBalance: "1",
      userRefLink: null,
    }
  },
  methods: {
    getProfile(token: string) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${token}`);
      fetch("https://ourearth.io/api/user/profile", {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      })
      .then(response => response.json())
      .then(result => {
        this.userBalance = Math.floor(result.balance.trees).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.communityBalance = Math.floor(result.total_balance.trees).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.userRefLink = `https://app.ourforest.io/invite/?referrer=${result.referral_code}`;
        document.querySelector('.welcome-view')?.classList.add('visible');
      })
      .catch(error => {
        console.log(error);
        this.$router.push({ name: 'Login' });
      })
    },
    openLink(url: string) {
      window.open(url);
      analytics.clickedLink(url);
    }
  },
  mounted() {
    document.dispatchEvent(new CustomEvent("requestOurforestUserEmail", {}));
    document.addEventListener('ourforestUserEmail', (event: any) => {
      this.getProfile(event.detail.token);
    });
  }
})
export default class Welcome extends Vue {

}
</script>

<style lang="scss">
.welcome-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  opacity: 0;
  transition: ease 0.2s;
}
.welcome-view.visible {
  opacity: 100;
}
.welcome-view__jumbo-title {
  font-size: 50px;
  margin-top: 16px;
  margin-bottom: 80px;
}
.welcome-view__emoji {
  position: relative;
  top: 2px;
  width: 22px;
  margin-left: 2px;
}

// CONFETTI STYLING
.welcome-view__confetti {
  position: absolute;
  top: -120px;
  pointer-events: none;
}
.welcome-view__confetti .confetti_container--confetti {
  animation-iteration-count: 1;
}

// SECTION STYLING
.welcome-view__section:first-of-type {
  margin-top: 120px;
}
.welcome-view__section:last-of-type {
  margin-bottom: 400px;
}
.welcome-view__section {
  margin-top: 32px;
  margin-bottom: 32px;
}
.welcome-view__section .welcome-view__section--divider {
  border-color: #CAE7AC;
}
.welcome-view__section .welcome-view__section--divider:first-of-type {
  margin-bottom: 28px;
}
.welcome-view__section .welcome-view__section--divider:last-of-type {
  margin-top: 28px;
}
.welcome-view__section .welcome-view__title {
  color: #3B6F06;
  font-size: 36px;
  margin-bottom: 80px;
}

// BOTH FIGURES STYLING
.welcome-view__both-figures-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-top: 60px;
}
.welcome-view__figure-number {
  font-size: 65px;
  margin: 0;
}
.welcome-view__figure-wrapper {
  display: flex;
}
.welcome-view__figure-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  width: 40px;
  height: 40px;
  background-color: #cbe4b1;
  border-radius: 100%;
}
.welcome-view__figure-title {
  margin-top: 4px;
  color: #3B6F06;
  margin-left: -40px;
}

// CARD STYLING
.welcome-view__card {
  text-align: left;
  margin-bottom: 20px;
}
.welcome-view__card--ad-screenshot {
  width: 100%;
}
</style>